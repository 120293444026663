import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import LandingPageIntroStyles from './LandingPageIntro.module.css'

export default () => {
  const data = useStaticQuery(query)
  return (
    <div className={LandingPageIntroStyles.opener + ' relative mb-32'}>
      <h1 className="leading-tight font-serif w-full lg:w-4/5 lg:mt-16 xl:w-3/4 lg:mb-16">
        »Grüß' Gott, mir send &shy;aus Winterbach!«
      </h1>
      <img
        src={data.trachtenpaar.publicURL}
        alt="Logo des Musikverein Trachtenkapelle Winterbach e.V."
        className={
          LandingPageIntroStyles.opener__image +
          ' w-full my-8 block mx-auto sm:w-2/3 lg:w-1/2 lg:pl-12 lg:absolute lg:pin-r lg:pin-t lg:mt-32'
        }
      />
      <p className="text-xl mt-6 lg:w-1/2">
        Mit diesem selbstgedichteten Liedvers aus dem Standschützenmarsch stellt
        sich der Verein bei Festen gern musikalisch vor – und alle singen mit:
        Groß und Klein, Jung und Alt, Musiker, Helfer und Zuhörer. Dieses
        Miteinander macht den Musikverein Trachtenkapelle Winterbach aus: »Wir«,
        der Musikverein, das sind rund 80 Musiker! Aber das sind auch mehr als
        80 Jugendliche in der Ausbildung! Fast 100 Helfer im Wirtschaftsteam! Um
        die 20 Frauen beim »Frauentreff«! Etwa 350 fördernde Mitglieder! Und ein
        einzigartiges, treues Publikum!
      </p>
      <p className="text-xl mt-4 lg:w-1/2">
        Auf dieser Seite erfahren Sie vieles über diesen Verein. Schauen Sie
        sich um und treten Sie bei Fragen oder Anregungen gern mit uns in{' '}
        <Link to="/kontakt">Kontakt</Link>.
      </p>
    </div>
  )
}

const query = graphql`
  query {
    trachtenpaar: file(relativePath: { eq: "trachtenpaar.svg" }) {
      publicURL
    }
  }
`
