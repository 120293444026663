import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faTimes,
  faInfo,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import LandingPageSection from '../components/LandingPageSection'
import BlogPreview from '../components/BlogPreview'
import EventPreview from '../components/EventPreview'
import LandingPageIntro from '../components/LandingPageIntro'
import MvTextPageHighlight from '../components/common/MvTextPageHighlight'

library.add(faBars, faTimes, faInfo, faDownload)

export default ({ data }) => {
  return (
    <Layout>
      <LandingPageIntro />

      <MvTextPageHighlight
        title="Anstehende Termine"
        subtitle="Den Musikverein erleben"
      >
        <EventPreview
          events={data.allEventsJson.edges.map(({ node }) => node)}
        />
      </MvTextPageHighlight>

      <LandingPageSection title="Neues vom Verein">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <BlogPreview
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            path={node.fields.slug}
            key={node.id}
          >
            {node.excerpt}
          </BlogPreview>
        ))}
      </LandingPageSection>

      <LandingPageSection title="Standschützenmarsch in Zeiten von Corona">
        <iframe
          width="560"
          height="315"
          className="m-auto block max-w-full"
          src="https://www.youtube-nocookie.com/embed/bqphmJkJMfQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </LandingPageSection>

      <MvTextPageHighlight
        title="Werden Sie Vereinsmitglied."
        subtitle="Sie möchten unseren Verein unterstützen?"
        link={{
          href: data.beitrittserklaerung.publicURL,
          text: 'Beitrittserklärung herunterladen',
        }}
      >
        Vereinsmitglied werden ist ganz einfach. Füllen Sie dazu die hier
        verlinkte Beitrittserklärung aus und senden Sie diese an unseren ersten
        Vorsitzenden Robin Unrath.
      </MvTextPageHighlight>
    </Layout>
  )
}

export const query = graphql`
  query {
    allEventsJson {
      edges {
        node {
          title
          start
          end
          description
          location
          cancelled
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "D. MMMM YYYY", locale: "de-DE")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    beitrittserklaerung: file(
      relativePath: { eq: "download/Beitrittserklaerung2024.pdf" }
    ) {
      publicURL
    }
  }
`
