import React from 'react'
import EventPanel from './EventPanel'
import { graphql, useStaticQuery } from 'gatsby'
import { getUpcomingEvents } from '../js/EventsHelper'

export default ({ events }) => {
  const NUMBER_OF_UPCOMING_EVENTS = 3
  let upcomingEvents = getUpcomingEvents(events)
  upcomingEvents = upcomingEvents.slice(0, NUMBER_OF_UPCOMING_EVENTS)

  const data = useStaticQuery(query)
  let downloads = []
  if (upcomingEvents[0]) {
    downloads = upcomingEvents[0].downloads
  }
  if (downloads) {
    downloads = downloads.map(download => {
      const file = data.allFile.nodes.find(
        file => `${file.name}.${file.extension}` === download.filename
      )
      return {
        url: file.publicURL,
        name: download.name,
      }
    })
  }

  let nextEventHasDescriptionOrDownloads = false
  if (upcomingEvents[0]) {
    nextEventHasDescriptionOrDownloads =
      !!upcomingEvents[0].description || !!(downloads && downloads.length)
  }

  return (
    <div className="flex flex-wrap flex-col md:flex-row w-full">
      {!upcomingEvents.length && <i>Derzeit sind keine Termine bekannt.</i>}
      {nextEventHasDescriptionOrDownloads && (
        <>
          <EventPanel
            className="w-full lg:w-3/5"
            event={upcomingEvents[0]}
            downloads={downloads}
            detailed
          />

          <div className="w-full lg:w-2/5">
            <EventPanel className="w-full" event={upcomingEvents[1]} />
            <EventPanel className="w-full" event={upcomingEvents[2]} />
          </div>
        </>
      )}
      {!nextEventHasDescriptionOrDownloads && (
        <>
          <EventPanel className="w-full md:w-1/3" event={upcomingEvents[0]} />
          <EventPanel className="w-full md:w-1/3" event={upcomingEvents[1]} />
          <EventPanel className="w-full md:w-1/3" event={upcomingEvents[2]} />
        </>
      )}
    </div>
  )
}

const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "download/events" } }) {
      nodes {
        name
        extension
        publicURL
      }
    }
  }
`
