import React from 'react'
import { formatTimespan } from '../helper/DateFormatting'
import MvPanel from './common/MvPanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EventPanelStyles from './EventPanel.module.css'

export default ({ event, className, downloads, detailed }) => {
  const cancelLabelStyle =
    'bg-red text-white px-4 font-bold absolute pin-t pin-r'

  function show(text) {
    if (event && event.cancelled) {
      return <s>{text}</s>
    }
    return text
  }
  if (event) {
    return (
      <MvPanel className={className + ' relative'} fullHeight>
        <div>{formatTimespan(event.start, event.end)}</div>
        {event.cancelled && <p className={cancelLabelStyle}>ABGESAGT!</p>}
        {event.title && <h3 className="my-2">{show(event.title)}</h3>}
        {event.location && <p className="italic">{show(event.location)}</p>}
        {detailed && (
          <>
            <p className={EventPanelStyles.eventPanel__description + ' mt-4'}>
              {show(event.description)}
            </p>
            {downloads &&
              downloads.map(download => (
                <a
                  href={download.url}
                  key={download.url}
                  title={`${download.name} herunterladen`}
                  className="mt-4 w-auto self-center"
                >
                  <FontAwesomeIcon icon="download" className="mr-2 pt-1" />
                  {download.name}
                </a>
              ))}
          </>
        )}
      </MvPanel>
    )
  } else {
    return <></>
  }
}
