import React from 'react'

export default ({ title, featured, className, children }) => {
  let wrapperStyles = ' mt-20'

  if (featured) {
    wrapperStyles += ' bg-primary p-8'
  }

  return (
    <div className={className + wrapperStyles}>
      <h2 className="text-center mb-8 lg:max-w-lg lg:mx-auto">{title}</h2>
      <div className="flex flex-col justify-center mx-auto sm:w-3/5 max-w-lg lg:flex-row lg:w-auto lg:items-baseline">
        {children}
      </div>
    </div>
  )
}
